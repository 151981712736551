import { defineStore } from "pinia";
import axios from "axios";
import apiUrl from "../Api/api_env";

export const useApiStore = defineStore("example", {
  state: () => ({
    data: null,
    loading: false,
    error: null,
  }),
  getters: {
    items: (state) => state.loading,
  },
  actions: {
    async fetchData() {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      const serialNumber = params.get("serialNumber");
      const type = params.get("type");
      const playerToken = params.get("playerToken");
      this.loading = true;
      this.error = null;

      try {
        const url =
            type === "playerOrderData"
                ? `${apiUrl}/gf/order/data/${serialNumber}`
                : `${apiUrl}/gf/order/content/${token}`;

        const response = await axios.get(
            url,
            type === "playerOrderData"
                ? {
                  headers: {
                    Authorization: playerToken,
                  },
                }
                : {}
        );

        if (response.data.code === "1") {
          this.data = JSON.parse(response.data.result.data); // 確保解析的是 `data`
        } else {
          this.error = "Invalid response code";
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
});