<template>
  <div v-if="apiStore.data">
    <div class="flex justify-start items-center font-bold text-xl mb-4">
      遊戲紀錄細單
      <label style="color:#f5f8f8">dev-0.0.03</label>
    </div>
    <GameName />
    <!-- <UserInfo :apiData="apiStore" /> -->
    <!-- <WinInfo :apiData="apiStore" /> -->
    <GameInfo />
  </div>
  <div
    v-else-if="apiStore.loading === false"
    class="h-full flex justify-center items-center flex-col"
  >
    <span class="mb-6"> loading... </span>
    <svg class="spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
  </div>
  <div v-else class="h-full flex justify-center items-center flex-col">
    <img class="w-16 mb-4" src="../assets/oops.png" />
    <span class="">查無資料或是已過期</span>
  </div>
</template>

<script>
import { onMounted, onUpdated } from "vue";
import { useApiStore } from "../store";
import GameName from "./GameName.vue";
import GameInfo from "./GameInfo.vue";
// import UserInfo from "./UserInfo.vue";
// import WinInfo from "./WinInfo.vue";

export default {
  name: "ContainerWrapper",
  components: {
    GameName,
    // UserInfo,
    // WinInfo,
    GameInfo,
  },
  setup() {
    const apiStore = useApiStore();
    onMounted(async () => {
      const fetchData = () => {
        apiStore.fetchData();
      };
      await fetchData();
    });
    onUpdated(() => {
      console.log(apiStore);
    });

    return {
      apiStore,
    };
  },
};
</script>

<style scoped>
html {
  width: 100%;
  height: 100%;
  background-clip: #fff;
}
</style>
