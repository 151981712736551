<template>
  <div class="flex justify-start items-start flex-wrap">
    <div class="w-full flex justify-start font-bold text-xl mb-4 mt-4">
      牌桌詳情
    </div>
    <div
      v-for="(value, key) in data?.GameInfo"
      :key="key"
      class="mb-16 w-full border border-custom-border flex flex-wrap justify-start py-4 px-4 pb-16 bg-white flex-row overflow-auto"
    >
      <div class="w-full mb-4 font-bold">{{ value.RoundID }}局</div>
      <div class="flex justify-center mr-16">
        <div class="mb-8">
          <table class="w-full">
            <tr>
              <th class="p-4 border border-custom-color">角色</th>
              <th class="p-4 border border-custom-color">頭墩</th>
              <th class="p-4 border border-custom-color">中墩</th>
              <th class="p-4 border border-custom-color">尾墩</th>
              <th class="p-4 border border-custom-color">未使用</th>
              <th class="p-4 border border-custom-color">總墩數</th>
              <th
                v-if="value.NoMoneyFlag"
                class="p-4 border border-custom-color"
              >
                原始贏分
              </th>
              <th class="p-4 border border-custom-color">贏分</th>
              <th class="p-4 border border-custom-color">服務費</th>
              <th class="p-4 border border-custom-color">牌組</th>
            </tr>
            <tr v-for="(item, index) in playerList" :key="index">
              <td class="round border border-custom-color p-4">
                座位{{ index + 1
                }}{{ data?.SeatPos === index + 1 ? "(玩家)" : "" }}
              </td>
              <td class="border border-custom-color p-4">
                <div class="flex flex-wrap">
                  <div
                    v-for="(card, number) in getCardType(
                      value[item]?.Top?.HandGroup
                    )"
                    :key="number"
                  >
                    {{ card }}
                  </div>
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div class="flex flex-wrap">
                  <div
                    v-for="(card, number) in getCardType(
                      value[item]?.Middle?.HandGroup
                    )"
                    :key="number"
                  >
                    {{ card }}
                  </div>
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div class="flex flex-wrap">
                  <div
                    v-for="(card, number) in getCardType(
                      value[item]?.Tail?.HandGroup
                    )"
                    :key="number"
                  >
                    {{ card }}
                  </div>
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div class="flex flex-wrap">
                  <div
                    v-for="(card, number) in getCardType(value[item]?.NoUse)"
                    :key="number"
                  >
                    {{ card }}
                  </div>
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div>
                  {{
                    value.WinPoints[index] > 0
                      ? `+${value.WinPoints[index]}`
                      : `${value.WinPoints[index]}`
                  }}
                </div>
              </td>
              <td
                v-if="value.NoMoneyFlag"
                class="border border-custom-color p-4"
              >
                <div>
                  {{
                    value.OriginalAmount[index] > 0
                      ? `+${value.OriginalAmount[index]}`
                      : `${value.OriginalAmount[index]}`
                  }}
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div>
                  {{
                    value.Amount[index] > 0
                      ? `+${value.Amount[index]}`
                      : `${value.Amount[index]}`
                  }}
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div>
                  {{
                    value.Commission[index] > 0 ? value.Commission[index] : "-"
                  }}
                </div>
              </td>
              <td class="border border-custom-color p-4">
                <div>{{ getHandCard(value[item]) }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="text-sm flex-wrap leading-6 mr-16">
        <div class="w-full">
          彩金牌: {{ getCardType([value.GoldCard])?.[0] }}
        </div>
        <div class="w-full">
          贏:
          <div v-if="Object.keys(value.Gain).length !== 0" class="ml-6">
            <div
              class="font-bold"
              v-for="(data, key) in Object.keys(value.Gain)"
              :key="key"
            >
              {{ `${bounsList[data]}` }}
              <span class="text-custom-value-color">
                {{ value.Gain[data] }}
              </span>
            </div>
          </div>
          <span v-else class="ml-1">無</span>
        </div>
        <div class="w-full">
          輸:
          <div class="ml-6">
            <div v-for="(data, key) in Object.keys(value.Lose)" :key="key">
              {{ `${bounsList[data]}` }}
              <span class="text-custom-value-color">
                -{{ value.Lose[data] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, computed } from "vue";
import { useApiStore } from "../store";
import { bounsList, handCardType } from "../../config/index";
// import { storeToRefs } from "pinia";

export default {
  name: "GameInfo",
  setup() {
    const apiStore = useApiStore();
    const orderData = computed(() => apiStore.data);
    watch(orderData, () => {});
    const getCardType = (cards) => {
      // 將數字轉為牌型字串
      const cardTypeString = cards.map((card) => {
        const color = Math.floor((card -1) / 13);
        let colorStr = "";
        switch (color) {
          case 0:
            colorStr = "♠";
            break;
          case 1:
            colorStr = "♥";
            break;
          case 2:
            colorStr = "♦";
            break;
          case 3:
            colorStr = "♣";
            break;
          default:
        }
        let num = card % 13;
        switch (num) {
          case 10:
            num = "J";
            break;
          case 11:
            num = "Q";
            break;
          case 12:
            num = "K";
            break;
          case 0:
            num = "A";
            break;
          default:
            num = num + 1;
        }
        return colorStr + num;
      });
      return cardTypeString;
    };
    const getHandCard = (value) => {
      return `${handCardType[value?.Top?.HandRankID]} + ${
        handCardType[value?.Middle?.HandRankID]
      } + ${handCardType[value?.Tail?.HandRankID]}`;
    };
    return {
      data: orderData,
      playerList: ["Player1", "Player2", "Player3", "Player4"],
      getCardType,
      getHandCard,
      bounsList,
    };
  },
};
</script>
<style scoped></style>
