<template>
  <div class="flex w-full justify-center items-center flex-wrap flex-row mt-6">
    <VueSelect
      v-model="selectTime"
      class="w-3/4 max-w-2xl !p-0 !font-custom-color bg-custom-select-color"
      :options="options"
    />
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useApiStore } from "../store";
import { getTimeByTargetDate } from "../lib/index";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import dayjs from "dayjs";

export default {
  name: "SearchList",
  components: {
    VueSelect,
  },
  props: {},
  setup() {
    const selectTime = ref({
      label: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
      value: "ca",
    });
    const option = ref([
      {
        label: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
        value: "0",
      },
      { label: dayjs().subtract(1, "day").format("YYYY-MM-DD"), value: "1" },
      {
        label: dayjs().subtract(2, "day").format("YYYY-MM-DD"),
        value: "2",
        selectable: () => {
          return false;
        },
      },
      { label: dayjs().subtract(3, "day").format("YYYY-MM-DD"), value: "3" },
      { label: dayjs().subtract(4, "day").format("YYYY-MM-DD"), value: "4" },
      { label: dayjs().subtract(5, "day").format("YYYY-MM-DD"), value: "5" },
    ]);
    const apiStore = useApiStore();
    const orderData = computed(() => apiStore.data);
    const dateCount = computed(() => apiStore.dateCount);
    watch(selectTime, () => {
      apiStore.fetchData(getTimeByTargetDate(selectTime.value?.label));
    });
    watch(dateCount, () => {
      option.value = option.value.filter((value) => {
        return (
          value.value == 0 ||
          dateCount.value?.find((data) => {
            return data.date === value.label;
          })
        );
      });
    });
    onMounted(async () => {
      apiStore.fetchData(getTimeByTargetDate(selectTime.value?.label));
    });

    return {
      data: orderData,
      selectTime: selectTime,
      options: option,
    };
  },
};
</script>

<style scoped></style>
