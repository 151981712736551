<template>
  <div
    class="flex justify-start items-center border border-custom-border bg-white flex-wrap flex-row"
  >
    <div class="flex py-4 px-4">
      <div>
        遊戲單號:<span
          class="ml-1 mr-4 custom-value-color text-custom-value-color font-bold"
          >{{ serialNumber }}</span
        >
      </div>
      <div class="">
        房型 :<span class="ml-1 mr-4 text-custom-value-color font-bold">{{
          roomType[data?.RoomType]
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useApiStore } from "../store";
import { roomType } from "../../config/index";

export default {
  name: "GameName",
  props: {},
  setup() {
    const apiStore = useApiStore();
    const orderData = computed(() => apiStore.data);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    // const gameCode = params.get("gameCode");
    const serialNumber = params.get("serialNumber");
    return {
      serialNumber,
      token,
      data: orderData,
      roomType,
    };
  },
};
</script>

<style scoped></style>
