<template>
  <div class="h-full py-8 px-8 bg-custom-bg-color">
    <ContainerWrapper />
  </div>
</template>

<script>
import ContainerWrapper from "./components/ContainerWrapper.vue";

export default {
  name: "App",
  components: {
    ContainerWrapper,
  },
};
</script>

<style>
html,
body,
#app {
  color: #395665;
  width: 100%;
  height: 100%;
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: #11a297;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
