import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import "./assets/tailwind.css";

import { createVfm } from "vue-final-modal";

const app = createApp(App);
const params = new URLSearchParams(window.location.search);
const type = params.get("type");

app.use(createPinia());
app.use(createVfm());

if (type === "playerOrderData") {
  await require("../cancer/src/main");
  document.querySelector("title").text = "遊戲細單";
} else {
  app.mount("#app");
}
