import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(timezone);
dayjs.extend(utc);
const timeFormat = "YYYY/MM/DD HH:mm:ss Z";

const formatRFC3339 = (date) => {
  if (date?.format) {
    return date?.format("YYYY-MM-DDTHH:mm:ssZ");
  }
};
export { formatRFC3339 };
// // 回傳前 30 分鐘的開始和結束時間
// export const getThirtyMinutesAgo = () => {
//   const now = dayjs(); // 現在時間
//   const thirtyMinutesAgo = now.subtract(30, "minute"); // 前 30 分鐘
//   return {
//     startTime: formatRFC3339(thirtyMinutesAgo),
//     endTime: formatRFC3339(now),
//   };
// };

// // 回傳昨天的開始和結束時間
// export const getYesterday = () => {
//   const yesterday = dayjs().subtract(1, "day"); // 昨天
//   return {
//     startTime: formatRFC3339(yesterday.startOf("day")),
//     endTime: formatRFC3339(yesterday.endOf("day")),
//   };
// };

// // 回傳前天的開始和結束時間
// export const getDayBeforeYesterday = () => {
//   const dayBeforeYesterday = dayjs().subtract(2, "day"); // 前天
//   return {
//     startTime: formatRFC3339(dayBeforeYesterday.startOf("day")),
//     endTime: formatRFC3339(dayBeforeYesterday.endOf("day")),
//   };
// };

export const getTimeByTargetDate = (date = "") => {
  const currentDate = dayjs();
  const targetDate = dayjs(date);

  const startTime = formatRFC3339(targetDate.startOf("day"));
  const endTime = formatRFC3339(targetDate.endOf("day"));

  return {
    startTime: startTime,
    endTime: targetDate.isSame(currentDate, "day")
      ? formatRFC3339(currentDate)
      : endTime,
  };
};

export const getTargetTimezone = (date = "") => {
  dayjs.tz.setDefault("Atlantic/Bermuda");
  return dayjs(date).format(timeFormat);
};

// export const UtfTimeFormat = (date = "") => {
//   dayjs.tz.setDefault("Atlantic/Bermuda");
//   return dayjs(date).format(timeFormat);
// };
