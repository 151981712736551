<template>
  <div
      class="flex justify-center flex-wrap h-5/6"
      :class="{
      'items-center': !orderList?.length,
      'items-start': orderList?.length > 0,
    }"
  >
    <div
        v-if="orderList?.length > 0"
        class="max-w-screen-xl w-full justify-center items-start flex flex-wrap text-xl mb-4 mt-4 ml-6 mr-6"
    >
      <div
          class="w-1/4 desktop:w-1/4 laptop:w-1/3 tablet:w-full m-4 p-4 cursor-pointer border border-custom-border bg-custom-item-color rounded-lg justify-center items-center"
          v-for="(value, key) in orderList"
          :key="key"
          @click="orderClick(value)"
      >
        <div class="border-b border-custom-border pb-2 mb-4">
          <div class="font-bold text-custom-value-color">
            {{ value.name }}
          </div>
          <div class="text-sm">{{ getOrderTime(value.order_time) }}</div>
        </div>
        <div class="flex justify-start items-start flex-nowrap justify-between">
          <div class="font-bold">{{ value.order_id }}</div>
          <div class="text-custom-win-color">
            {{ value.win > 0 ? "+" : "" }}{{ value.win }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="apiStore.errorCode === '110013'">驗證失敗。</div>
    <div v-else>查無紀錄</div>
  </div>
</template>

<script>
import { watch, computed } from "vue";
import { useApiStore } from "../store";
import { getTargetTimezone }from '../lib/index'


export default {
  name: "OrderList",
  setup() {
    const apiStore = useApiStore();
    const orderList = computed(() => apiStore.data);
    watch(orderList, () => {});
    return {
      orderList: orderList,
      apiStore: apiStore,
    };
  },
  methods: {
    orderClick(inputItem) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      window.open(
        `/?playerToken=${token}&gameCode=${inputItem.game_code}&serialNumber=${inputItem.order_id}&type=playerOrderData`
      );
    },
    getOrderTime(time){
      return getTargetTimezone(time)
    }
  },
};
</script>
<style scoped></style>
