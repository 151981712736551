<template>
  <!-- <div v-if="apiStore.data" class="my-8"> -->
  <div class="my-8 h-full">
    <div
      class="w-full flex justify-center items-center font-bold text-xl pb-8 border-b border-custom-border"
    >
      遊戲紀錄查詢<label style="color:#f5f8f8">dev-0.0.13</label>
    </div>
    <SearchList v-if="apiStore.errorCode !== '110013'" />
    <OrderList />
  </div>
  <!-- <div
    v-else-if="apiStore.loading === false"
    class="h-full flex justify-center items-center flex-col"
  >
    <span class="mb-6"> loading... </span>
    <svg class="spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
  </div>
  <div v-else class="h-full flex justify-center items-center flex-col">
    <img class="w-16 mb-4" src="../assets/oops.png" />
    <span class="">查無資料或是已過期</span>
  </div> -->
</template>

<script>
import { onMounted, onUpdated } from "vue";
import { useApiStore } from "../store";
import SearchList from "./SearchList.vue";
import OrderList from "./OrderList.vue";

export default {
  name: "ContainerWrapper",
  components: {
    SearchList,
    OrderList,
  },
  setup() {
    const apiStore = useApiStore();
    onMounted(async () => {});
    onUpdated(() => {
      // console.log(apiStore);
    });

    return {
      apiStore,
    };
  },
};
</script>

<style scoped>
html {
  width: 100%;
  height: 100%;
  background-clip: #fff;
}
</style>
