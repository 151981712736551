import { defineStore } from "pinia";
import axios from "axios";
import API_Items from "../Api/api_env";
import { formatRFC3339 } from "../lib/index";
import dayjs from "dayjs";

export const useApiStore = defineStore("example", {
  state: () => ({
    data: null,
    loading: false,
    dateCount: [],
    error: null,
    errorCode: null,
  }),
  getters: {
    items: (state) => state.loading,
  },
  actions: {
    async fetchData(searchTime) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      try {
        // 呼叫 getOrderDateCount API
        const currentDate = formatRFC3339(dayjs());
        const timeDataList = await axios.get(
            `${API_Items}/gf/order/date/count/${currentDate}`,
            {
              headers: {
                Authorization: token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
        );

        if (timeDataList.data.code === "1") {
          this.dateCount = timeDataList.data.result;
        }

        // 呼叫 getOrderList API
        const formData = new FormData();
        formData.append("count", 200);
        formData.append("page", 1);
        formData.append("start_time", searchTime.startTime);
        formData.append("end_time", searchTime.endTime);

        const response = await axios.post(`${API_Items}/gf/order/list/7day`, formData, {
          headers: {
            Authorization: token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        console.log(response);

        if (response.data.code === "1") {
          this.data = response.data.result.data;
          console.log(this.data)
          this.error = null;
        }
        this.loading = true;
      } catch (error) {
        if (error.response?.data?.code === "110013") {
          this.errorCode = error.response.data.code;
        }
        this.error = error.message;
        console.log(error);
      }
    },
  },
});