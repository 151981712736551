export const bounsList = {
  Gun: "打槍分數",
  HomeRun: "全壘打分數",
  TopThree: "衝三分數",
  TopGoldThree: "彩金衝三分數",
  MidGoldThree: "中墩彩金三條分數",
  MidGoldFullhouse: "中墩彩金葫蘆分數",
  MidFourOfAKind: "中墩鐵支分數",
  MidStraightFlush: "中墩同花順分數",
  idGoldFourOfAKind: "中墩彩金四天王分數",
  MidFiveOfAKind: "中墩五枚分數",
  TailGoldThree: "尾墩彩金三條分數",
  TailGoldFullhouse: "尾墩彩金葫蘆分數",
  TailFourOfAKind: "尾墩鐵支分數",
  TailStraightFlush: "尾墩同花順分數",
  TailGoldFourOfAKind: "尾墩彩金四天王分數",
  TailFiveOfAKind: "尾墩五枚分數",
};

export const handCardType = {
  14: "五枚",
  13: "同花順",
  12: "彩金四天王",
  11: "鐵支",
  10: "彩金葫蘆",
  9: "葫蘆",
  8: "對子同花",
  7: "同花",
  6: "順子",
  5: "彩金衝三/彩金三條  ",
  4: "衝三/三條",
  3: "兩對",
  2: "一對",
  1: "烏龍",
};
export const roomType = {
  5: "新手區",
  20: "高級區",
  100: "至尊區",
};
